import styled, { css } from 'styled-components';
import { breakpoints, colors } from '../../styles/config';

export const ReviewBubble = styled.div<{ isLeftAligned: boolean }>`
    display: block;
    width: 100%;
    padding: 1rem;
    background: ${colors.grey6};
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    margin-bottom: 0.5rem;

    @media (min-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: ${p => (p.isLeftAligned ? '0' : '1rem')};
        border-bottom-right-radius: ${p => (p.isLeftAligned ? '1rem' : '0')};
    }
`;

export const ReviewFooter = styled.div<{ isLeftAligned: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: ${p => (p.isLeftAligned ? 'flex-start' : 'flex-end')};
`;

export const ReviewContainer = styled.div<{ isLeftAligned: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    @media (min-width: ${breakpoints.tablet}px) {
        align-items: ${p => (p.isLeftAligned ? 'flex-start' : 'flex-end')};
    }
`;

export const ReviewInfoText = styled.span<{ withMargin?: string }>`
    font-size: 12px;
    color: ${colors.grey60};
    ${p =>
        p.withMargin &&
        css`
            margin-right: ${p.withMargin};
        `}
`;

export const ReviewFooterStars = styled(ReviewInfoText)<{ isLeftAligned: boolean }>`
    display: inline-block;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: ${colors.grey40};
    ${p =>
        p.isLeftAligned &&
        css`
            margin-left: auto;
        `};
    ${p =>
        !p.isLeftAligned &&
        css`
            order: -1;
            margin-right: auto;
        `};
`;
