import styled from 'styled-components';
import { colors } from '../styles/config';

export const TextButton = styled.button`
    display: inline-block;
    vertical-align: baseline;
    padding: 0;
    margin: 0;
    border: none;
    color: ${colors.blue};
    cursor: pointer;

    &:hover,
    &:focus {
        color: ${colors.blueDarker};
        text-decoration: underline;
    }
`;
