import styled, { css } from 'styled-components';
import { breakpoints } from '../../styles/config';

const ReviewContainer = styled.div<{ isPulledRight?: boolean }>`
    margin-bottom: 1.5rem;
    @media (min-width: ${breakpoints.tablet}px) {
        margin-bottom: 2rem;
        ${p =>
            p.isPulledRight === true &&
            css`
                padding-left: 20%;
            `} ${p =>
            p.isPulledRight === false &&
            css`
                padding-right: 20%;
            `}
    }
    @media (min-width: ${breakpoints.desktop}px) {
        margin-bottom: 2.5rem;
    }
`;

export default ReviewContainer;
