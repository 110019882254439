import React from 'react';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSanitizedHtml from '../util/useSanitizedHtml';
import { useI18n } from '../../i18n/useI18n';
import { useFormattedDate } from '../util/useFormattedDate';
import { TextButton } from '../TextButton';
import { ReviewBubble, ReviewContainer, ReviewFooter, ReviewFooterStars, ReviewInfoText } from './Review.styles';
import { IReview } from '../../models/Review';
import { range } from 'ramda';
import { Text } from '../typography/Text';

export interface ReviewProps extends IReview {
    currentLanguage: string;
    align?: 'left' | 'right';
}

export function Review({
    author,
    stars,
    content,
    date,
    originalContent,
    originalLanguage,
    currentLanguage,
    align,
}: ReviewProps): JSX.Element {
    const { t } = useI18n();

    const sanitizedContent = useSanitizedHtml(content);
    const sanitizedOriginalContent = useSanitizedHtml(originalContent);
    const formattedDate = useFormattedDate(date, currentLanguage);
    const canShowOriginal = originalLanguage !== currentLanguage;
    const isLeftAligned = align !== 'right';

    const [showOriginal, setShowOriginal] = React.useState(false);
    const toggleShowOriginal = React.useCallback(() => setShowOriginal(shown => !shown), []);

    const starIcons = React.useMemo(() => {
        return range(0, stars).map((i: number) => <FontAwesomeIcon key={i} icon={faStar} />);
    }, [stars]);

    return (
        <ReviewContainer isLeftAligned={isLeftAligned}>
            <ReviewBubble isLeftAligned={isLeftAligned}>
                <Text
                    lang={showOriginal ? originalLanguage : currentLanguage}
                    data-testid="review"
                    style={{ marginBottom: '0.5rem' }}
                    dangerouslySetSanitizedHTML={showOriginal ? sanitizedOriginalContent : sanitizedContent}
                />
                {canShowOriginal && (
                    <ReviewInfoText>
                        <ReviewInfoText withMargin="0.5rem">
                            {t('review.translated-with-google-translate')}
                        </ReviewInfoText>
                        <TextButton type="button" onClick={toggleShowOriginal} data-testid="original-text-toggle">
                            {showOriginal ? t('review.show-translated') : t('review.show-original')}
                        </TextButton>
                    </ReviewInfoText>
                )}
            </ReviewBubble>
            <ReviewFooter isLeftAligned={isLeftAligned}>
                <ReviewInfoText withMargin="1rem" data-testid="author">
                    {author}
                </ReviewInfoText>
                <ReviewInfoText data-testid="date">{formattedDate}</ReviewInfoText>
                <ReviewFooterStars data-testid="stars" isLeftAligned={isLeftAligned}>
                    {starIcons}
                </ReviewFooterStars>
            </ReviewFooter>
        </ReviewContainer>
    );
}
